<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vs-card>
      <div style="padding: 0 1rem">
        <h1 class="mt-5">المتاجر</h1>

        <div class="vx-row mt-8">
          <div class="vx-col my-3 sm:w-1/4 w-full">
            <div class="mb-2"><label for="">إسم المتجر</label></div>
            <v-select
              label="name"
              :options="stores"
              v-model="filterData.store"
              data-vv-as="إسم المتجر"
              v-validate="'required'"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="store_id"
              class="w-full"
            ></v-select>
            <span class="text-danger text-sm" v-show="errors.has('store_id')">{{
              errors.first("store_id")
            }}</span>
          </div>
          <div class="vx-col my-3 sm:w-1/4 w-full">
            <div class="mb-2"><label for="">الجنس</label></div>
            <v-select
              label="name"
              :options="productsOptions"
              v-model="filterData.gender"
              data-vv-as="الجنس"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              name="gender_id"
              class="w-full"
            ></v-select>
            <!-- <span class="text-danger text-sm" v-show="errors.has('gender_id')">{{
              errors.first("gender_id")
            }}</span> -->
          </div>
          <div class="vx-col my-3 sm:w-1/4 w-full">
            <div class="mb-2"><label for="">القسم الرئيسي</label></div>
            <v-select
              label="name"
              :options="categories"
              v-model="filterData.category"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              class="w-full"
            ></v-select>
          </div>
          <div class="vx-col my-3 sm:w-1/4 w-full">
            <div class="mb-2"><label for="">القسم الفرعي</label></div>
            <v-select
              label="name"
              :options="sub_categories"
              v-model="filterData.sub_categories"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              class="w-full"
            ></v-select>
          </div>
          <div class="vx-col my-3 sm:w-1/5 w-full">
            <vs-button
              class="text-md"
              @click="getData(current_Page)"
              color="primary"
              type="filled"
              >بحث</vs-button
            >
          </div>
        </div>
      </div>
    </vs-card>
    <div
      id="data-list-thumb-view"
      class="data-list-container"
      v-if="selected_store"
    >
      <vs-table
        ref="table"
        multiple
        search
        v-model="selected"
        :max-items="itemsPerPage"
        :data="products"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <div class="flex flex-wrap-reverse items-center">
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
              <div
                class="
                  p-4
                  shadow-drop
                  rounded-lg
                  d-theme-dark-bg
                  cursor-pointer
                  flex
                  items-center
                  justify-center
                  text-lg
                  font-medium
                  w-32
                "
              >
                <span class="mr-2">العمليات</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>إزالة</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon
                      icon="ArchiveIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>أرشفة</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ADD NEW -->
            <vs-button
              v-if="filterData.store != null"
              @click="newProduct"
              class="
                btn-add-new
                p-3
                mb-4
                mr-4
                rounded-lg
                cursor-pointer
                flex
                items-center
                justify-center
              "
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">اضافة منتج</span>
            </vs-button>
          </div>

          <!-- ITEMS PER PAGE -->
          <vs-dropdown
            vs-trigger-click
            class="cursor-pointer mb-4 mr-4 items-per-page-handler"
          >
            <div
              class="
                p-4
                border border-solid
                d-theme-border-grey-light
                rounded-full
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-between
                font-medium
              "
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  products.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : products.length
                }}
                of {{ queriedItems }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th>الصورة</vs-th>
          <vs-th sort-key="title">الإسم</vs-th>
          <vs-th sort-key="category_id">القسم الرئيسي</vs-th>
          <vs-th sort-key="sub_category_id">القسم الفرعي</vs-th>
          <vs-th sort-key="price">السعر</vs-th>
          <vs-th>العمليات</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr
              :data="products"
              :key="indextr"
              v-for="(product, indextr) in data"
            >
              <vs-td
                v-if="product.first_media"
                class="table-image-container flex justify-center items-center"
              >
                <img
                  :src="product.first_media.path"
                  class="table-image"
                  alt="صورة المنتج"
                />
              </vs-td>

              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ product.title }}
                </p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ product.category.name }}</p>
              </vs-td>

              <vs-td>
                <p class="product-category">{{ product.sub_category.name }}</p>
              </vs-td>

              <vs-td>
                <p class="product-price">{{ product.price }}LYD</p>
              </vs-td>

              <vs-td>
                <a @click="editData(product.id)">
                  <feather-icon
                    icon="EditIcon"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  />
                </a>
                <feather-icon
                  icon="ArchiveIcon"
                  svgClasses="w-5 h-5 hover:text-warning stroke-current"
                  class="ml-2"
                  @click.stop="confirm(product.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-pagination
        class="mt-4"
        :total="lastPage"
        v-model="current_Page"
        @change="getData(current_Page)"
      ></vs-pagination>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      productsOptions: [],
      categories: [],
      sub_categories: [],
      stores: [],
      product_id: "",
      filterData: {
        store: null,
        gender: null,
        category: null,
        sub_category: null,
      },
      selected_store: false,
      search: "",
      selected: [],
      products: [],
      current_Page: 1,
      itemsPerPage: 10,
      lastPage: 0,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.products.length;
    },
  },

  watch: {
    "filterData.gender": function () {
      this.categories = this.filterData.gender.categories;
    },
    "filterData.category": function () {
      this.sub_categories = this.filterData.category.sub_categories;
    },
  },

  methods: {
    ...mapActions("products", [
      "fetchClassBProducts",
      "fetchProductOptions",
      "archiveProduct",
    ]),
    ...mapActions("stores", ["fetchStores"]),
    getData(page) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.selected_store = true;
          let data = new FormData();
          data.set("page", page);
          data.set(
            "store_id",
            this.filterData.store != null ? this.filterData.store.id : ""
          );
          data.set(
            "gender_id",
            this.filterData.gender != null ? this.filterData.gender.id : ""
          );
          data.set(
            "category_id",
            this.filterData.category != null ? this.filterData.category.id : ""
          );
          data.set(
            "sub_category_id",
            this.filterData.sub_category != null
              ? this.filterData.sub_category.id
              : ""
          );

          this.$vs.loading();

          this.fetchClassBProducts(data)
            .then((response) => {
              this.products = response.data.data.data;
              this.current_Page = response.data.data.current_page;
              this.total = response.data.data.total;
              this.lastPage = response.data.data.last_page;
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.errorDialog(error);
              this.$vs.loading.close();
            });
        }
      });
    },
    editData(productId) {
      this.$router.push({
        name: "stores-management-edit-product",
        params: { id: productId },
      });
    },
    newProduct() {
      this.$router.push({
        name: "stores-management-new-product",
        params: { storeId: this.filterData.store.id },
      });
    },
    confirm(id) {
      this.product_id = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "تأكيد",
        text: "هل انت متأكد من أرشفة هذا المنتج؟",
        acceptText: "تأكيد",
        cancelText: "الغاء",
        accept: this.deleteData,
      });
    },
    deleteData() {
      this.archiveProduct(this.product_id)
        .then(() => {
          this.getData(this.current_Page);
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم أرشفة المنتج بنجاح",
            color: "success",
          });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  mounted() {},
  created() {
    this.fetchStores().then((response) => {
      this.stores = response.data.data;
    });

    this.fetchProductOptions().then((response) => {
      this.productsOptions = response.data.data;
    });
  },
};
</script>

<style lang="scss">
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.table-image-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .table-image {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
